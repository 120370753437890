import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import ProductsList from './components/ProductsList';
import Root from './routes/root';
import ErrorPage from './components/ErrorPage';
import SingleProduct, { loader as productLoader } from './components/SingleProduct';
import Search from './components/Search';
import CartPage from './components/CartPage';
import Login, { loader as loginLoader } from './components/Login';
import Admin, { loader as adminLoader } from './components/Admin';
import Logout, { loader as logoutLoader } from './components/Logout';
import './index.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: '/',
        element: <ProductsList />,
      },
      {
        path: '/products/:productID',
        element: <SingleProduct/>,
        loader: productLoader
      },
      {
        path: '/search',
        element: <Search />
      },
      {
        path: '/cart',
        element: <CartPage />
      },
      {
        path: '/login',
        element: <Login />,
        loader: loginLoader
      },
      {
        path: '/logout',
        element: <Logout />,
        loader: logoutLoader
      },
      {
        path: '/admin',
        element: <Admin />,
        loader: adminLoader
      }
    ],
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
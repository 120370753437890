import './ProductView.css';

function ProductView({ visible, handleProductViewVisibility, src }) {
    return (
        <div className={visible === 'visible' ? 'ProductView visible' : 'ProductView'} aria-hidden="true">
            <button type="button" className="ProductViewClose" onClick={() => handleProductViewVisibility('') }>&times;</button>
            <div className="ProductViewContent">
                {src !== '' ? <img src={src} alt="" /> : '' }
            </div>
        </div>
    )
}

export default ProductView;
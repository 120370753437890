import Cookie  from '../cookie';
import { redirect } from 'react-router-dom';

export function loader() {
    if(localStorage.getItem('user') !== null && Cookie.getCookie('token') !== null) {
        Cookie.removeCookie('token');
        localStorage.removeItem('user');
        window.location = '/login';
    } else {
        return redirect('/');
    }    
}

function Logout() {
    
}

export default Logout;
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import './AppHeader.css';

function Header() {
    return (
        <header className="AppHeader">
            <div className="container">
                
                    <div className="logo">
                        <Link to="/">Future Fashion</Link>
                    </div>
                    <Navigation/>
            </div>
        </header>
    )
}

export default Header;
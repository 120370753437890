export default class Cart {
    constructor() {
        this.items = [];
        this.total = 0.00;
        this.storage = localStorage;

        this.sync();
    }

    sync() {
        if(this.storage.getItem('cart') !== null) {
            const cart = JSON.parse(this.storage.getItem('cart'));
            this.items = cart.items;
            this.total = cart.total;
        } else {
            this.storage.setItem('cart', JSON.stringify({
                items: [],
                total: 0.00
            }));
        }
    }

    empty() {
        this.items = [];
        this.total = 0.00;
        this.save();
    }

    save() {
        const cart = {
            total: this.total,
            items: this.items
        };
        this.storage.setItem('cart', JSON.stringify(cart));
    }

    removeFromCart(id) {
        if(this.items.length === 0) {
            return false;
        }
        const index = this.items.findIndex(i => i.product.id === id);
        if(index !== -1) {
            this.items.splice(index, 1);
            this.totals();
            this.save();
        }
    }

    updateCart(itemsToUpdate) {
        if(this.items.length === 0) {
            return false;
        }

        for(const item of itemsToUpdate) {
            let { id, qty } = item;
            let index = this.items.findIndex(i => i.product.id === id);
            if(index !== -1) {
                this.items[index].qty = qty;
            }
        }
        this.totals();
        this.save();
    }

    addToCart(product, qty) {
        if(this.items.length === 0) {
            const item = {
                product, qty
            };
            this.items.push(item);
            this.totals();
            this.save();
        } else {
            const existing = this.items.find(i => i.product.id === product.id);
            if(!existing) {
                const item = {
                    product, qty
                };
                this.items.push(item);
                this.totals();
                this.save();
        }
        }
    }

    totals() {
        if(this.items.length === 0) {
            return false;
        }
        let tot = 0.00;
        for(const item of this.items) {
            tot += item.product.price * item.qty;
        }
        this.total = tot;
    }
}
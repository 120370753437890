import './Admin.css';
import Cookie  from '../cookie';
import { redirect } from 'react-router-dom';
import ProductForm from './ProductForm';

export function loader() {
    if(localStorage.getItem('user') === null || Cookie.getCookie('token') === null) {
        return redirect('/login');
    }    
}

function Admin() {
    return (
        <div className="Admin">
            <h1>Dashboard</h1>
            <ProductForm />
        </div>
    )
}

export default Admin;
import { Link } from 'react-router-dom';
import './Navigation.css';
import Cookie from '../cookie';
import { useState, useEffect } from 'react';

function Navigation() {
    const [loginLink, setLoginLink] = useState('');
    const [loginText, setLoginText] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        const logged = localStorage.getItem('user') !== null && Cookie.getCookie('token') !== null;
        const link = logged ? '/logout' : '/login';
        const text = logged ? 'Logout' : 'Login';
        setLoginLink(link);
        setLoginText(text);
        setLoggedIn(logged);
    }, []);

    return (
        <nav className="Navigation">
            <Link to="/">Home</Link>
            {loggedIn ? <Link to="/admin">Dashboard</Link> : ''}
            <Link to={loginLink}>{loginText}</Link>
            <Link to="/search" className="SearchLink" style={{ backgroundImage: 'url(/images/search.png)'}}>Search</Link>
            <Link to="/cart" className="CartLink" style={{ backgroundImage: 'url(/images/cart.png)'}}>Cart</Link>
        </nav>
    )
}

export default Navigation;
import { useLoaderData } from 'react-router-dom';
import { getProduct } from '../api';
import Cart from '../cart';
import './SingleProduct.css';

export async function loader({ params }) {
  return getProduct(params.productID);
}

function SingleProduct() {
  const product = useLoaderData();

  const handleAddToCart = () => {
      const cart = new Cart();
      cart.addToCart(product, 1);
      window.location = '/cart';
  };

  return (
    <article className="SingleProduct">
        <div className="SingleProductInfo">
            <h1>{product.name}</h1>
            <div>
                &euro; {product.price.toFixed(2).replace('.', ',')}
            </div>
            <p>{product.description}</p>
            <p>
              <button type="button" onClick={() => handleAddToCart()} className="SingleProductAdd">Add to cart</button>
            </p>
        </div>
        <figure className="SingleProductImage">
            <img src={`https://ffapi.gabrieleromanato.dev/public/images/products/${product.image}`} alt={product.name} />
        </figure>
    </article>

  )
}

export default SingleProduct;
const API_URL = 'https://ffapi.gabrieleromanato.dev/v1/';

export async function getProduct(id) {
    const url = `${API_URL}products/${id}`;
    const res = await fetch(url);
    return await res.json();
}

export async function searchProducts({ term, page, perpage }) {
    const url = `${API_URL}search`;
    const params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ term, perpage, page })
    };
    const res = await fetch(url, params);
    return await res.json();
}

export async function createProduct({ name, price, description, token }) {
    const url = `${API_URL}products`;
    const params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${token}`
        },
        body: JSON.stringify({ name, price, description })
    };
    const res = await fetch(url, params);
    return await res.json();    
}

export async function login({ email, password }) {
    const url = `${API_URL}login`;
    const params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
    };
    const res = await fetch(url, params);
    return await res.json();    
}
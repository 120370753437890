import { useState } from 'react';
import { Link } from 'react-router-dom';
import { searchProducts } from '../api';
import './Search.css';

function Search() {

    const [overlayVisible, setOverlayVisible] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const handleSearchSuggestions = searchTerm => {
        if(searchTerm && searchTerm.length > 3 && !/^\s+$/.test(searchTerm)) {
            const params = {
                term: searchTerm,
                page: 1,
                perpage: 6
            };
            searchProducts(params).then(res => {
                setSuggestions(res.products);
            });
        } else {
            setSuggestions([]);
        }
    };

    return (
        <div className="Search">
        <div className={overlayVisible === 'visible' ? 'SearchOverlay visible' : 'SearchOverlay'} aria-hidden="true"></div>
        <form action="" method="get" className="SearchForm">
            <div className="fields-wrap">
               
                <label htmlFor="term" className="sr">Search</label>
                <span className="term-wrap">
                    <input type="text" onChange={(e) => handleSearchSuggestions(e.target.value)} onBlur={() => setOverlayVisible('') } onFocus={() => setOverlayVisible('visible') } name="term" id="term" placeholder="Search" />
                    <button type="button" className="SearchCancel" aria-hidden="true">&times;</button>
                </span>
                <div className={suggestions.length === 0 ? 'SearchSuggestions': 'SearchSuggestions visible'} aria-hidden="true">
                    {suggestions.length === 0 ? '' : suggestions.map((sugg, i) =>
                        <div className="suggestion" key={i}>
                           <span><Link to={`/products/${sugg.id}`}>{sugg.name}</Link></span>
                        </div>    
                    )}
                </div>
                <input type="submit" value="Search" className="sr" />
            </div>
        </form>
        </div>
    )
}

export default Search;
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Error from './Error';
import Loader from './Loader';
import ProductListItem from './ProductListItem';
import ProductPagination from './ProductPagination';
import ProductView from './ProductView';
import './ProductsList.css';

function ProductsList() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [products, setProducts] = useState([]);
    const [pages, setPages] = useState(null);
    const [productViewVisible, setProductViewVisible] = useState('');
    const [productViewImageSrc, setProductViewImageSrc] = useState('')

    const handleProductViewVisibility = (visibleClass = '') => {
        setProductViewVisible(visibleClass);
    };

    const handleProductViewImageSrc = (src = '') => {
        setProductViewImageSrc(src);
    };

    const getProducts = (page = 1, perpage = 6) => {
        const params = new URLSearchParams({ page, perpage }).toString();
        fetch('https://ffapi.gabrieleromanato.dev/v1/products/?' + params).then(res => res.json())
        .then(
            (result) => {
            setIsLoaded(true);
            setProducts(result.products);
            setPages(result.pages);
            },
            (error) => {
            setIsLoaded(true);
            setError(error);
            }
        );
    };

    useEffect(() => {
        handleProductViewVisibility();
        getProducts();
    }, []);

    if(error) {
        return <Error message="Please try again later."/>
    } else if(!isLoaded) {
        return <Loader/>
    } else {
        return ( 
        <>    
        <div className="Products">
           {products.map((prod, i) => 
             <Link to={`/products/${prod.id}`} key={i}>
                <ProductListItem  handleProductViewImageSrc={handleProductViewImageSrc} handleProductViewVisibility={handleProductViewVisibility} name={prod.name} image={prod.image} id={prod.id} />
             </Link>   
            )} 
            
        </div>
        <ProductPagination pages={pages} getProducts={getProducts} />
        <ProductView src={productViewImageSrc} visible={productViewVisible} handleProductViewVisibility={handleProductViewVisibility} />
        </>
        
        );
    }
}

export default ProductsList;
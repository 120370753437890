import Cart from '../cart';
import './CartPage.css';
import { useState, useEffect } from 'react';
function CartPage() {
    const cart = new Cart();
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0.00);
    useEffect(() => {
        setItems(cart.items);
        setTotal(cart.total);
    }, [cart.items, cart.total]);
    const handleRemoveProduct = id => {
        cart.removeFromCart(id);
        setItems(cart.items);
        setTotal(cart.total);
    };

    const handleQuantityChange = (id, qty) => {
        const update = [{ id, qty }];
        cart.updateCart(update);
        setItems(cart.items);
        setTotal(cart.total);
    };

    const handleEmptyCart = () => {
        cart.empty();
        setItems(cart.items);
        setTotal(cart.total);
    };

    return (
        <div className="CartPage">
            {items.length === 0 ? <p>Your cart is empty.</p> :
            <table className="Cart">
                <thead>
                    <tr>
                        <th scope="col">Product</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Price</th>
                        <th scope="col">Actions</th>
                    </tr>    
                </thead> 
                <tbody>
                    {items.map((item, index) =>
                        <tr key={index}>
                            <td>{item.product.name}</td>
                            <td>
                                <input onChange={(e) => handleQuantityChange(item.product.id, e.target.value)} type="text" className="CartQty" value={item.qty} />
                            </td>
                            <td>&euro; {item.product.price.toFixed(2).replace('.', ',')}</td>
                            <td>
                                <button onClick={() => handleRemoveProduct(item.product.id) } type="button" className="CartRemove">&times;</button>
                            </td>
                        </tr>
                    )} 
                    <tr>
                        <td>
                            <button onClick={handleEmptyCart} type="button" className="CartEmpty">Empty cart</button>
                        </td>
                        <td colSpan="3">
                            <strong>Total: </strong> &euro; {total.toFixed(2).replace('.', ',')}
                        </td>
                    </tr>   
                </tbody>   
            </table>
            }
        </div>
    )
}

export default CartPage;
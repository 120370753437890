import ProductPaginationLink from "./ProductPaginationLink";
import './ProductPagination.css';

function ProductPagination({ pages, getProducts }) {
    const paginationData = [];

    for(let i = 0; i < pages; i++) {
        paginationData.push({
            page: (i + 1)
        });
    }

    return (
        <nav className="Pagination"> 
            {paginationData.map((p) => 
                <ProductPaginationLink key={p.page} page={p.page} getProducts={getProducts} />
            )}   
        </nav>
    )
}

export default ProductPagination;
const Cookie = {
    setCookie(name, value, expiresDays) {
         const date = new Date();
         date.setTime(date.getTime() + (expiresDays * 24 * 60 * 60 * 1000));
         const expires = 'expires=' + date.toUTCString();
         const cookie = `${name}=${value};${expires};path=/`;
         document.cookie = cookie;
    },
    getCookie(name) {
        const cookieName = `${name}=`;
        const decoded =  decodeURIComponent(document.cookie);
        const parts = decoded.split('; ');
        let cookie = null;
        for(const part of parts) {
            if(part.includes(cookieName)) {
                cookie = part.substring(cookieName.length);
            }
        }
        return cookie;
    },
    removeCookie(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    }
};

export default Cookie;
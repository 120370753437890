import { useState } from 'react';
import { login } from '../api';
import Cookie  from '../cookie';
import { redirect } from 'react-router-dom';
import './Login.css';

export function loader() {
    if(localStorage.getItem('user') !== null && Cookie.getCookie('token') !== null) {
        return redirect('/admin');
    }    
}

function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const handleEmail = evt => {
        setEmail(evt.target.value);
    };

    const handlePassword = evt => {
        setPassword(evt.target.value);
    };

    const handleSubmit = evt => {
        evt.preventDefault();
        if(!email || !password) {
            return setError('Invalid login');
        }
        login({ email, password }).then(result => {
            if(result.error) {
                setError(result.error);
            } else if(result.token) {
                localStorage.setItem('user', email);
                Cookie.setCookie('token', result.token, 1);
                window.location = '/admin';
            } else {
                setError('Oops, try again later');
            }
        });
    };

    return (
        <div className="Login">
            <form onSubmit={(e) => handleSubmit(e)} action="" method="post" noValidate>
                <div>
                    <label htmlFor="email">E-mail</label>
                    <input type="text" onChange={(e) => handleEmail(e)} name="email" id="email" value={email} />
                </div>
                <div>
                    <label htmlFor="password">Password</label>
                    <input type="password" onChange={(e) => handlePassword(e)} name="password" id="password" value={password} />
                </div>
                <div>
                    <button type="submit">Login</button>
                </div>
                {error ? <p className="LoginError">{error}</p> : ''}
            </form>
        </div>
    )
}

export default Login;
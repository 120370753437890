import './ProductForm.css';
import { useState } from 'react';
import Cookie from '../cookie';
import { createProduct } from '../api';

function ProductForm() {
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [errors, setErrors] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleSubmit = evt => {
        evt.preventDefault();
        const data = {
            name, price, description, token: Cookie.getCookie('token')
        };
        createProduct(data).then(resp => {
            if(resp.error) {
                setErrors(resp.error);
            } else if(resp.name) {
                setSuccess('Product added successfully.');
            } else {
                setErrors('Oops, try agaain later.');
            }
        });
    };

    const handleName = evt => {
        setName(evt.target.value);
    };

    const handlePrice = evt => {
        setPrice(evt.target.value);
    };

    const handleDescription = evt => {
        setDescription(evt.target.value);
    };

    return (
        <form className="ProductForm" method="post" onSubmit={(e) => handleSubmit(e)}>
            <div>
                <label htmlFor="name">Name</label>
                <input type="text" name="name" id="name" value={name} onChange={(e) => handleName(e)} />
            </div>
            <div>
                <label htmlFor="price">Price</label>
                <input type="text" name="price" id="price" value={price} onChange={(e) => handlePrice(e)} />
            </div>
            <div>
                <label htmlFor="description">Description</label>
                <textarea name="description" id="description" value={description} onChange={(e) => handleDescription(e)}></textarea>
            </div>
            <div>
                <button type="submit">Add product</button>
            </div>
            {errors ? <div className="Errors">{errors}</div> : ''}
            {success ? <div className="Success">{success}</div> : '' }
        </form>
    )
}

export default ProductForm;
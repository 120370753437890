import './Loader.css';

function Loader() {
    return (
        <div className="Loader">
            <div className="ripple">
                <div></div><div></div>
            </div>
        </div>
    )
}

export default Loader;
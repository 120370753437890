import './ProductListItem.css';
import ProductViewBtn from './ProductViewBtn';

function ProductListItem({ image, name, handleProductViewVisibility, handleProductViewImageSrc }) {
    const src = `https://ffapi.gabrieleromanato.dev/public/images/products/${image}`;
    return(
        <figure className="ProductListItem">
            <img src={src} alt={name} />
            <ProductViewBtn imageSrc={src} handleProductViewImageSrc={handleProductViewImageSrc} handleProductViewVisibility={handleProductViewVisibility} />
        </figure>
    )
}

export default ProductListItem;